import React from 'react'
import Footer1 from '../Footer1'
import AboutMain from '../AboutMain'
import AboutBody from '../AboutBody'

function AboutPage() {
  return (
    <>
        <AboutMain/>
        <AboutBody/>
        <Footer1/>
    </>
  )
}

export default AboutPage
