import React from 'react'
import Navbar from '../Navbar'
import Footer1 from '../Footer1'
import Banner2 from '../Banner2'

function ServicePage() {
  return (
    <>
      <Navbar/>
      <Banner2/>
      <Footer1/>
    </>
  )
}

export default ServicePage

